@import '../../style.scss';

.home-container {
  max-width: 740px;
  padding: 20px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}

.picture {
  height: 100px;
  border-radius: 50%;
  margin-right: 20px;
}

.about-me {
  display: flex;
  align-items: center;
}
