@import 'constants/constants.module.scss';

html {
  font-family: 'Martel Sans', sans-serif;
}

body {
  -webkit-font-smoothing: antialiased;
  margin: 0px;
}
