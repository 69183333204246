@import '../../style.scss';

.nav {
  display: flex;
  height: 70px;
  text-decoration: none;
  background-color: black;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  font-weight: 600;
  margin-bottom: 30px;
}
